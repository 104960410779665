import { Title, Container, Main } from "../../components";
import styles from "./styles.module.css";
import MetaTags from "react-meta-tags";

const About = ({ updateOrders, orders }) => {
  return (
    <Main>
      <MetaTags>
        <title>О проекте</title>
        <meta name="description" content="Фудграм - О проекте" />
        <meta property="og:title" content="О проекте" />
      </MetaTags>

      <Container>
        <h1 className={styles.title}>Привет!</h1>
        <div className={styles.content}>
          <div>
            <h2 className={styles.subtitle}>Что это за сайт?</h2>
            <div className={styles.text}>
              <p className={styles.textItem}>
                Представляю вам проект, созданный во время обучения в Яндекс
                Практикуме. Этот проект — часть учебного курса, но Backend его
                создан мною, по заданию Яндекса.
                <br />
                Огромное спасибо родителям, что настояли чтобы я окончила
                обучение!
              </p>
              <p className={styles.textItem}>
                Цель этого сайта — дать возможность пользователям создавать и
                хранить рецепты на онлайн-платформе. Кроме того, можно скачать
                список продуктов, необходимых для приготовления блюда,
                просмотреть рецепты друзей и добавить любимые рецепты в список
                избранных.
              </p>
              <p className={styles.textItem}>
                Чтобы использовать все возможности сайта — нужна регистрация.
                Проверка адреса электронной почты не осуществляется, вы можете
                ввести любой email. Пароль, если забудете, мы вам тоже не вернем
                (только может если сильно попросите)
              </p>
              <p className={styles.textItem}>
                Заходите и делитесь своими любимыми рецептами!
              </p>
            </div>
          </div>
          <aside>
            <h2 className={styles.additionalTitle}>Ссылки</h2>
            <div className={styles.text}>
              <p className={styles.textItem}>
                Код проекта находится тут -{" "}
                <a
                  href="https://github.com/PalatkinaRegina/foodgram"
                  className={styles.textLink}
                >
                  Github
                </a>
              </p>
              <p className={styles.textItem}>
                Авторы проекта: <br />
                <a
                  href="https://github.com/PalatkinaRegina"
                  className={styles.textLink}
                >
                  Регина (Backend) и<br />
                </a>
                <a
                  href="https://github.com/evi1ghost"
                  className={styles.textLink}
                >
                  Evi1ghost (все остальное)
                </a>
              </p>
            </div>
            <div className={styles.text}>
              <p className={styles.textItem}>
                Ревьювер -{" "}
                <a
                  href="https://practicum.yandex.ru"
                  className={styles.textLink}
                >
                  Алексей Брюшинин
                </a>
              </p>
            </div>
          </aside>
        </div>
      </Container>
    </Main>
  );
};

export default About;
